import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { motion, MotionProps } from 'framer-motion';
import { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { BOX_SHADOW } from '../../../../cnusr/constants';
import { flippingBox } from '../../../animations';
import { useSequenceAnimation } from '../../../hooks';
import { NormalProblem, problemStore } from '../../../stores';
import { getColorData } from '../../../utils';

import { BoxFace } from './boxFace';

const useStyles = makeStyles({
  nonDollar: {
    opacity: '.55',
  },
  selectable: {
    boxShadow: BOX_SHADOW,
    cursor: 'pointer',
  },
  white: {
    backgroundColor: 'white',
  },
  white_square_unselectable: {
    boxShadow: 'inset 0 0 0 2px rgba(211, 211, 211)',
  },
});

interface Props {
  problem: NormalProblem;
  initial?: MotionProps['initial'];
  replica?: boolean;
}

export const BoxAnimation: FC<Props> = ({
  problem,
  initial = 'initial',
  replica,
}) => {
  const skips = problem.type === 'PRACTICE_CORRECTIONS' ? 0 : problem.skips;
  const { lvl, penalty, status } = problem;
  const isPenaltyOrWrong = problem.status === 'wrong' || !!penalty;
  const classes = useStyles();
  const isDollar = problemStore(state => state.dollars.includes(problem.id));
  const dollarsOnly = problemStore(state => state.dollarsOnly);
  const isWhite =
    problem.lvl === 'not enough data' || problem.lvl === 'untried';
  const latestLevel = getColorData(lvl || 'red').level;
  const previousLevel = useRef(latestLevel);
  const { boxFaceClassName, variants } = flippingBox.small;
  const [, reRender] = useState(0);
  const {
    controls,
    startAnimation,
    stopAnimation,
    isAnimating,
  } = useSequenceAnimation({
    sequence: ['grow', 'flip', 'scaleDown'],
    onComplete: () => {
      previousLevel.current = latestLevel;
      stopAnimation();
    },
  });

  useLayoutEffect(() => {
    if (latestLevel < previousLevel.current) {
      previousLevel.current = latestLevel;
      reRender(i => i + 1);
    }
  }, [latestLevel]);

  useEffect(() => {
    // only animate if leveling up to yellow or higher
    if (latestLevel > previousLevel.current && latestLevel > 1) {
      startAnimation();
    }
    // level changed, so change color (without animation)
    else if (latestLevel !== previousLevel.current) {
      previousLevel.current = latestLevel;
      reRender(i => i + 1);
    }
  }, [latestLevel, startAnimation, stopAnimation]);

  const isSelectable = (isDollar || !dollarsOnly) && !isAnimating && !replica;

  return (
    <motion.div
      animate={controls}
      className={clsx('innerBox', {
        [classes.nonDollar]: dollarsOnly && !isDollar && !isAnimating,
        [classes.selectable]: isSelectable,
        [classes.white]: isWhite,
        [classes.white_square_unselectable]: isWhite && !isSelectable,
        wrong: status === 'wrong' || penalty,
      })}
      initial={initial}
      variants={variants}
    >
      <BoxFace
        className={boxFaceClassName}
        isDollar={isDollar}
        level={previousLevel.current}
        skips={skips}
        isPenaltyOrWrong={isPenaltyOrWrong}
      />
      <BoxFace
        className={boxFaceClassName}
        isBack
        isDollar={isDollar}
        level={latestLevel}
        skips={skips}
        isPenaltyOrWrong={isPenaltyOrWrong}
      />
    </motion.div>
  );
};
