import { FC } from 'react';
import Draggable from 'react-draggable';

import { ModalHeader, modalStyle } from '@gmm/problem';

import { postExamRequest } from '../../../../studentApp/api';
import { studentAppModalStore } from '../../../../studentApp/stores/studentAppModalStore';

import { ListItem } from './listItem';

interface ExamsProps {
  close: () => void;
}

export const Exams: FC<ExamsProps> = ({ close }) => {
  const exams = studentAppModalStore(state => state.availableExams);

  const requestExam = (sitId: number) => {
    postExamRequest({ sitId });
    close();
  };

  return (
    <Draggable handle=".gmm-exams-modal">
      <div className="blue" style={modalStyle}>
        <ModalHeader
          close={close}
          title={'Exams'}
          dragHandle={'gmm-exams-modal'}
        />

        <div style={{ height: '15px' }}></div>

        <div className="limit-list-height">
          {exams.map(exam => (
            <ListItem
              key={exam.studentInTestId}
              text={exam.testName}
              launch={() => requestExam(exam.studentInTestId)}
              score={exam.total ? `${exam.points}/${exam.total}` : undefined}
              disabled={!!exam.total}
              isFollowUp={exam.isFollowUp}
              hasBeenTurnedIn={exam.hasBeenTurnedIn}
            />
          ))}
        </div>
      </div>
    </Draggable>
  );
};
