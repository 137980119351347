import Tooltip from '@material-ui/core/Tooltip';
import { CSSProperties, FC } from 'react';

import { LIST_ITEM_COLOR } from '../../../constants';

interface ListItemProps {
  date?: string;
  text: string;
  corrections?: boolean;
  color?: string;
  score?: string;
  disabled?: boolean;
  launch?: () => void;
  minWidth?: number;
  isFollowUp?: boolean;
  hasBeenTurnedIn?: boolean;
}

const workListItemDateNameStyle: CSSProperties = {
  position: 'absolute',
  left: '10px',
  top: '50%',
  transform: 'translateY(-50%)',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
};

const followUpPillStyle: CSSProperties = {
  position: 'absolute',
  right: '10px',
  top: '50%',
  transform: 'translateY(-50%)',
  borderRadius: '10px',
  marginLeft: '5px',
  padding: '5px',
  backgroundColor: LIST_ITEM_COLOR,
  color: 'white',
};

export const ListItem: FC<ListItemProps> = props => {
  const minWidth = props.minWidth ? props.minWidth + 'px' : undefined;
  let text = props.corrections ? 'Corrections, ' + props.text : props.text;

  if (props.hasBeenTurnedIn) text += ' (turned in)';

  return (
    <Tooltip title={text}>
      <div
        style={{
          minWidth: minWidth,
          backgroundColor: props.hasBeenTurnedIn ? 'lightgreen' : undefined,
        }}
        className={
          'work-list-item' +
          (props.disabled ? ' unclickable-work-list-item' : '')
        }
        onClick={!props.disabled ? props.launch : undefined}
      >
        {!props.isFollowUp && <DateAndName {...props} />}
        {props.isFollowUp && <FollowUpItem {...props} />}
        <Score {...props} />
      </div>
    </Tooltip>
  );
};

const DateAndName: FC<ListItemProps> = props => {
  const color = props.color ?? LIST_ITEM_COLOR;

  return (
    <div
      style={{
        ...workListItemDateNameStyle,
        width: props.score ? '70%' : '95%',
      }}
    >
      {props.date && <span style={{ color: color }}>{`${props.date}: `}</span>}
      {props.corrections && <span style={{ color: 'red' }}>Corrections, </span>}
      <span style={{ color: color }}>{props.text}</span>
    </div>
  );
};

const FollowUpItem: FC<ListItemProps> = props => {
  return (
    <div>
      <span
        style={{
          ...workListItemDateNameStyle,
          width: '65%',
          color: LIST_ITEM_COLOR,
        }}
      >
        {props.text.split(' (Follow-up)')[0]}
      </span>
      <span style={followUpPillStyle}>Follow-up</span>
    </div>
  );
};

const Score: FC<ListItemProps> = props => {
  const color = props.color ?? LIST_ITEM_COLOR;

  return (
    <>
      {props.score && (
        <div className="work-list-item-score" style={{ color: color }}>
          {props.score}
        </div>
      )}
    </>
  );
};
