import { AttemptGetter, BaseDTO } from '../getters';
import { problemModalState } from '../modals';
import { buildTTSDTO, TTSDTO } from '../readAloud';
import { roundRect2 } from '../renderers';
import { lineFont, lineFontSize, getFont } from '../state';
import { BasicObject, MenuConstants, getWordMenu, floater } from '../uiObjects';
import { decodeString, getFullTopLeftLocation } from '../utils';

import { AttemptGetterStatus } from './attemptGetter';

// These will be needed until further refactoring of index.js
interface InlineChoiceGetterDependencies {
  grabFocus(getter: AttemptGetter): void;
  isTesting(): boolean;
  getStaticPanel(data: any): BasicObject;
  getStaticPanelFromText(
    text: string,
    font?: string,
    tts?: TTSDTO
  ): BasicObject;
  getText(
    x: number,
    y: number,
    text: string,
    fontSize?: number,
    multiline?: boolean,
    maxW?: number
  ): BasicObject;
  EXTRA_PIXELS_UNDER_PROBLEM: number;
}

/* DTO as json
 * {
 *   "correct": "correct" | "wrong" | "untried" // result of prior submission, only present if not test, 'untried' if not test and never had valid attempt.
 *   "validAttempt": true | false // whether current state is 'turn-in-able' for test, only provided if test
 *   "t": "inlineChoice"
 *   "agId": #
 *   "paragraphs" : [
 *      {
 *        xmlGraph: {lines and align} // result of calling StringsAndValuesParagraph.toJson, supplied to client's getStaticPanel function
 *      }
 *   ]
 *   "optionGroups" : [ // groups of options to put on pull-down menus
 *     [ // one group of options
 *       { // one option
 *         "text" : string // appears on pull-down menu as an option
 *         "state" : "checked" | "unchecked" // whether this option is currently chosen
 *         "correct": true | false, // result of prior submission, not provided if no submission yet or testing
 *       },
 *       ... // more options
 *     ],
 *     ... // more groups of options
 *   ]
 * }
 */

interface Option {
  text: string;
  state: 'checked' | 'unchecked';
  // not defined if testing
  correct: boolean | undefined;
}

type OptionGroup = Option[];

interface DTO extends BaseDTO {
  paragraphs: any[];
  optionGroups: OptionGroup[];
  prompt?: string;
}

export class InlineChoiceGetter extends AttemptGetter {
  VERTICAL_SPACE_BETWEEN_PARAGRAPHS = 15;

  dto: DTO;

  problemJS: InlineChoiceGetterDependencies;

  menus: Map<number, InlineChoiceMenu> = new Map();

  constructor(
    problemJS: InlineChoiceGetterDependencies,
    dtoFromServer: DTO,
    status: AttemptGetterStatus,
    changeHandler: any,
    x?: number,
    y?: number
  ) {
    super(dtoFromServer, status, changeHandler, x || 0, y || 0);

    this.problemJS = problemJS;
    this.dto = dtoFromServer;

    this.gmmName = 'inline choice getter';
  }

  buildUI(submitButton: BasicObject): void {
    // Server may send a prompt, such as 'Choose the correct option from each dropdown menu.'
    if (this.dto.prompt) {
      let decoded = decodeString(this.dto.prompt);

      // Target for cleansing: why does decodeString possibly return undefined?
      // But there is at least one test of decodeString that expects undefined, so for now, keep it happy.
      if (decoded === undefined) decoded = this.dto.prompt;
      const prompt = this.problemJS.getStaticPanelFromText(
        decoded,
        undefined,
        buildTTSDTO(decoded)
      );

      // margin below prompt
      prompt.viewportH += 12;
      // prompt gets added to the widgets wrapped by the InlineChoiceGetter
      this.add(prompt);
    }

    this.dto.paragraphs.forEach(paragraph => {
      // includes creation of menus, see getTilesHelper in index.js to follow trail
      const paragraphPanel: BasicObject = this.problemJS.getStaticPanel(
        paragraph
      );

      paragraphPanel.viewportH += this.VERTICAL_SPACE_BETWEEN_PARAGRAPHS;
      this.add(paragraphPanel);
    });

    this.layoutChildrenUD();
    this.centerHorizontally();
    this.sizeMeToLowestAndRightmostChildren();

    submitButton.viewportX = this.viewportW / 2 - submitButton.viewportW / 2;
    submitButton.viewportY =
      this.viewportH + this.VERTICAL_SPACE_BETWEEN_PARAGRAPHS;

    this.add(submitButton);

    // Recompute getter dimensions now that submit button has been added
    this.sizeMeToLowestAndRightmostChildren();

    // Aim for getter height sufficent to fully paint all pop-ups on canvas
    this.menus.forEach(menu => {
      const tempPopup = menu.buildPopupMenu(true);
      const pixelsNeeded = tempPopup.viewportH;
      const pixelsAvailable =
        this.viewportH -
        tempPopup.viewportY +
        this.problemJS.EXTRA_PIXELS_UNDER_PROBLEM;

      if (pixelsNeeded > pixelsAvailable) {
        this.viewportH += pixelsNeeded - pixelsAvailable;
      }
    });
  }

  addMenu(optionGroupIndex: number, menu: InlineChoiceMenu): void {
    this.menus.set(optionGroupIndex, menu);
  }

  getMenu(optionGroupIndex: number): InlineChoiceMenu | undefined {
    return this.menus.get(optionGroupIndex);
  }

  getOptionGroup(optionGroupIndex: number): OptionGroup {
    return this.dto.optionGroups[optionGroupIndex];
  }

  // Record and apply state sent from server
  update(updatedJson: DTO): void {
    this.dto.optionGroups = updatedJson.optionGroups;

    updatedJson.optionGroups.forEach((optionGroup, index) => {
      this.getMenu(index)?.setOptionGroup(optionGroup);
    });
  }

  hasChoiceForEachOptionGroup(): boolean {
    let foundUnchecked = false;

    this.dto.optionGroups.forEach(optionGroup => {
      foundUnchecked =
        foundUnchecked ||
        !optionGroup.some(option => option.state === 'checked');
    });

    return !foundUnchecked;
  }

  grabFocus(): void {
    this.problemJS.grabFocus(this);
  }

  serializeAttempt(testing: boolean): { optionGroups: Option[][] } | undefined {
    // if NOT testing, require a choice per group of options
    if (!testing && !this.hasChoiceForEachOptionGroup()) {
      problemModalState().alert({
        msg: 'You need to make at least one more choice.',
        top: 'Invalid',
      });

      return;
    }

    return { optionGroups: this.dto.optionGroups };
  }
}

// Called during parsing of xmlGraph to create menu object embedded in paragraph.
// (see index.js.getTilesHelper)
// unparsedText format: #,#
// first number: agId
// second number: optionGroupIndex
export function getInlineChoiceMenu(
  problemJS: InlineChoiceGetterDependencies,
  unparsedText: string,
  attemptGetters: Map<number, AttemptGetter>
): InlineChoiceMenu {
  const split = unparsedText.split(',');
  const agId = parseInt(split[0]);
  const optionGroupIndex = parseInt(split[1]);

  const inlineChoiceGetter: InlineChoiceGetter = attemptGetters.get(
    agId
  ) as InlineChoiceGetter;

  // getInlineChoiceMenu is called frequently by getTilesHelper during paragraph sizing,
  // avoid extra rebuilds
  const existing = inlineChoiceGetter.getMenu(optionGroupIndex);

  if (existing) return existing;

  const menu = new InlineChoiceMenu(
    problemJS,
    inlineChoiceGetter.getOptionGroup(optionGroupIndex),
    inlineChoiceGetter
  );

  inlineChoiceGetter.addMenu(optionGroupIndex, menu);

  return menu;
}

// These will be needed until further refactoring of index.js
interface InlineChoiceMenuDependencies {
  isTesting(): boolean;
  getStaticPanelFromText(text: string): BasicObject;
  getText(
    x: number,
    y: number,
    text: string,
    fontSize?: number,
    multiline?: boolean,
    maxW?: number
  ): BasicObject;
}

// A rectangle embedded in a paragraph showing the 'minimized' menu.
// Click on it to get a pop-up showing Options.
class InlineChoiceMenu extends BasicObject {
  gmmName = 'InlineChoiceMenu';

  MARGIN = 5;
  PADDING_AFTER = 2;
  ARROW_COLOR = '#999999';
  CORRECT_BORDER_COLOR = 'green';
  WRONG_BORDER_COLOR = 'magenta';
  DEFAULT_TEXT_COLOR = 'black';
  WRONG_TEXT_COLOR = '#C8C8C8';
  BACKGROUND_SELECTED_TEXT_COLOR = '#F0F0F0';
  FLOATER_ROW_HEIGHT: number;

  inlineChoiceGetter: InlineChoiceGetter;
  optionGroup!: OptionGroup;
  currentChoiceDisplayer!: BasicObject;
  selectedOption!: Option;
  problemJS: InlineChoiceMenuDependencies;

  constructor(
    problemJS: InlineChoiceMenuDependencies,
    optionGroup: OptionGroup,
    inlineChoiceGetter: InlineChoiceGetter
  ) {
    super(0, 0);

    this.problemJS = problemJS;

    this.FLOATER_ROW_HEIGHT = 2 * lineFontSize;

    this.inlineChoiceGetter = inlineChoiceGetter;

    this.setOptionGroup(optionGroup);
  }

  // Count element as 1 character in
  // order to be able to highlight it
  // when it is read aloud
  getPaintedCharCount(): number {
    return 1;
  }

  // 'start' is ignored for an InlineChoiceMenu: we just highlight the whole thing
  setHighlightReadAloud(_i: number): void {
    this.setHighlight(true);
  }

  // Updated state sent from getter (which receives the state from server)
  setOptionGroup(optionGroup: OptionGroup): void {
    this.optionGroup = optionGroup;

    // **************** MINIMIZED MENU ***************
    // three panels horizontally aligned:
    // statusHolder (X or checkmark), menu.currentChoiceDisplayer, and arrow
    this.children = [];

    let widestOption!: BasicObject;

    this.optionGroup.forEach((option: Option) => {
      option.text = decodeString(option.text) || option.text;
      const sizeMe = this.problemJS.getStaticPanelFromText(option.text);

      if (!widestOption || sizeMe.viewportW > widestOption.viewportW) {
        widestOption = sizeMe;
      }
    });

    const menuHeight: number = widestOption.viewportH + 2 * this.MARGIN;

    // left side of minimized menu, displays 'X' for wrong, checkmark for correct
    const statusHolder: StatusHolder = new StatusHolder(this, menuHeight);

    this.add(statusHolder);

    // Container for a child panel that shows user-selected option, blank if no choice yet
    this.currentChoiceDisplayer = new BasicObject();

    this.currentChoiceDisplayer.viewportW = widestOption.viewportW;
    this.currentChoiceDisplayer.viewportH = menuHeight;
    this.currentChoiceDisplayer.viewportX = statusHolder.gR();

    this.add(this.currentChoiceDisplayer);

    // right-side of minimized menu has gray down arrow
    const arrow: Arrow = new Arrow(this, menuHeight);

    this.add(arrow);

    this.viewportW = arrow.gR() + this.MARGIN + this.PADDING_AFTER;
    this.viewportH = menuHeight;

    optionGroup.forEach(option => {
      if (option.state === 'checked') {
        this.setChoiceByOption(option);
      }
    });
  }

  setChoiceByOption(selectedOption: Option): void {
    this.selectedOption = selectedOption;

    this.optionGroup.forEach(option => {
      option.state = option === selectedOption ? 'checked' : 'unchecked';
    });

    this.currentChoiceDisplayer.children = [];
    this.currentChoiceDisplayer.add(
      this.problemJS.getStaticPanelFromText(selectedOption.text)
    );
    this.currentChoiceDisplayer.centerVertically();
  }

  setChoiceByText(selectedText: string): void {
    this.optionGroup.forEach(option => {
      if (option.text === selectedText) {
        this.setChoiceByOption(option);
      }
    });
  }

  // Rectangle around three horizontal elements, paints inline with paragraph
  paintMe(ctx: CanvasRenderingContext2D): void {
    roundRect2(
      ctx,
      0,
      0,
      this.viewportW - this.PADDING_AFTER,
      this.viewportH,
      6,
      undefined,
      this.getBorderColor(),
      2
    );
  }

  // Popup menu appears when user clicks on minimized menu, appears to float over canvas
  mouseDownResponse(): boolean {
    if (!this.isEnabled()) return false;

    this.inlineChoiceGetter.changedMaybe(this.inlineChoiceGetter.agId);

    this.inlineChoiceGetter.grabFocus();

    // Triggers system for painting temporary panel on top of 'world' - build this after grabbing focus here
    floater.setPanel(this.buildPopupMenu());

    return true;
  }

  buildPopupMenu(hidden?: boolean): any {
    const textPerMenuRow: string[] = [];

    this.optionGroup.forEach(option => {
      textPerMenuRow.push(option.text);
    });

    const menuOptionW =
      this.viewportW - 2 * MenuConstants.MENU_HORIZONTAL_MARGIN;

    const popupMenu = getWordMenu(
      this.problemJS,
      textPerMenuRow,
      getMenuListener(this),
      getPaintOption(this),
      { w: menuOptionW, h: this.FLOATER_ROW_HEIGHT },
      undefined,
      true,
      hidden
    );

    popupMenu.setBackgroundColor(MenuConstants.MENU_STANDARD_BACKCOLOR);
    popupMenu.viewportMargin = MenuConstants.MENU_STANDARD_BORDER_THICKNESS;
    popupMenu.viewportMarginColor = MenuConstants.MENU_STANDARD_BORDER_COLOR;

    popupMenu.viewportX = getFullTopLeftLocation(this).x;
    popupMenu.viewportY = getFullTopLeftLocation(this).y;

    return popupMenu;
  }

  // Distinct forecolor for option text that has been submitted and marked wrong
  getMenuOptionFillStyle(word: string): string {
    return this.isWrongText(word)
      ? this.WRONG_TEXT_COLOR
      : this.DEFAULT_TEXT_COLOR;
  }

  getBorderColor(): string {
    if (this.problemJS.isTesting())
      return MenuConstants.MENU_STANDARD_BORDER_COLOR;
    if (this.isCorrect()) return this.CORRECT_BORDER_COLOR;
    else if (this.isWrong()) return this.WRONG_BORDER_COLOR;
    else return MenuConstants.MENU_STANDARD_BORDER_COLOR;
  }

  isSelectedText(text: string): boolean {
    return (
      this.optionGroup.find(option => option.text === text)?.state === 'checked'
    );
  }

  isWrongText(text: string): boolean {
    const option = this.optionGroup.find(option => option.text === text);

    if (!option) return false;

    return option.correct !== undefined && !option.correct;
  }

  isCorrect(): boolean {
    return this.optionGroup.some(option => option.correct);
  }

  isWrong(): boolean {
    if (this.isCorrect()) return false;

    return this.optionGroup.some(
      option => option.correct !== undefined && !option.correct
    );
  }

  selectedOptionHasBeenSubmitted(): boolean {
    return this.selectedOption.correct !== undefined;
  }

  // Once a menu is correctly submitted, we no longer permit changes
  // (unless testing)
  isEnabled(): boolean {
    return this.problemJS.isTesting() || !this.isCorrect();
  }

  // menu fakes membership as a line element to
  // seamlessly utilize positioning logic for paragraphs
  // that blend Strings and Values. A consequence is that
  // it must implement three expected line element functions:
  // setLine, buildSizeRecursive, and setEditable
  setLine(): void {}

  buildSizeRecursive(): void {}

  setEditable(): void {}
}

class StatusHolder extends BasicObject {
  menu: InlineChoiceMenu;

  constructor(menu: InlineChoiceMenu, menuHeight: number) {
    super();
    this.menu = menu;
    this.setAllDim(menuHeight);
  }

  paintMe(ctx: CanvasRenderingContext2D): void {
    ctx.save();
    ctx.beginPath();

    // checkmark
    if (this.menu.isCorrect()) {
      ctx.fillStyle = 'green';
      const fontSize = 1.125 * lineFontSize;

      ctx.font = 'bold ' + fontSize + 'px ' + lineFont;
      ctx.textBaseline = 'top';
      const inset = (this.viewportH - fontSize) / 2;

      ctx.fillText('\u2713', inset, inset);
    }
    // X: don't paint if current selection hasn't been submitted
    else if (
      this.menu.isWrong() &&
      this.menu.selectedOptionHasBeenSubmitted()
    ) {
      ctx.lineWidth = 2;
      ctx.strokeStyle = 'magenta';
      const inset = 0.3 * this.viewportH;
      const side = 0.4 * this.viewportH;

      ctx.translate(inset, inset);
      ctx.moveTo(0, 0);
      ctx.lineTo(side, side);
      ctx.moveTo(side, 0);
      ctx.lineTo(0, side);
    }

    ctx.stroke();
    ctx.restore();
  }
}

class Arrow extends BasicObject {
  menu: InlineChoiceMenu;

  constructor(menu: InlineChoiceMenu, menuHeight: number) {
    super();
    this.menu = menu;

    const arrowSide = menuHeight - menu.MARGIN;

    this.setAllDim(arrowSide);
    this.viewportX = this.menu.currentChoiceDisplayer.gR();
    this.viewportY = 0.5 * menu.MARGIN;
  }

  paintMe(ctx: CanvasRenderingContext2D): void {
    if (this.menu.isCorrect()) return;

    ctx.save();
    ctx.beginPath();

    const insetX = 0.25 * this.viewportW;
    const insetY = 0.25 * this.viewportH;
    const w = 0.5 * this.viewportW;
    const h = 0.4 * this.viewportH;

    ctx.translate(insetX, insetY);
    ctx.moveTo(0, 0);
    ctx.lineTo(w / 2, h);
    ctx.lineTo(w, 0);
    ctx.fillStyle = this.menu.ARROW_COLOR;
    ctx.fill();

    ctx.restore();
  }
}

// ************** POPUP MENU support ******************

// Supplied to popup menu for painting each option's text
function getPaintOption(menu: InlineChoiceMenu) {
  return function (ctx: CanvasRenderingContext2D, word: string): void {
    ctx.save();

    // fill background of selected option
    if (menu.isSelectedText(word)) {
      ctx.fillStyle = menu.BACKGROUND_SELECTED_TEXT_COLOR;
      ctx.fillRect(
        -MenuConstants.MENU_HORIZONTAL_MARGIN,
        0,
        menu.viewportW,
        menu.FLOATER_ROW_HEIGHT
      );
    }

    ctx.textBaseline = 'middle';
    ctx.font = getFont();
    ctx.fillStyle = menu.getMenuOptionFillStyle(word);
    ctx.fillText(word, 0, menu.FLOATER_ROW_HEIGHT / 2);
    ctx.stroke();

    ctx.restore();
  };
}

// Supplied to popup menu to listen for mouse events on each option
function getMenuListener(menu: InlineChoiceMenu) {
  return function (selectedText: string): boolean {
    if (!menu.isWrongText(selectedText)) {
      menu.setChoiceByText(selectedText);
    }
    // Signal canvasMouseDown to NOT remove the 'floater' (menu)
    // because user clicked on item that was already marked wrong.
    else floater.setPreserve(true);

    return true;
  };
}
